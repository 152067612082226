import React from "react";
import { Link } from "react-router-dom";

const RefreshLink = ({ to, children, sx, ...props }) => {
  const handleClick = (event) => {
    event.preventDefault();
    window.location.href = to;
  };

  return (
    <Link
      to={to}
      onClick={handleClick}
      style={sx}
      {...props}
    >
      {children}
    </Link>
  );
};

export default RefreshLink;
